import React from 'react'
import Testing from '../components/RecaptchaV3'
import Layout from '../components/Layout'

const rowPadding = {
    padding: "100px 0"
  }
 

export default function test() {
  return (
    <Layout>
        <section className='w-100 contact-us-bg' style={{marginTop: '74px'}}>
            <Testing/>
        </section>

      {/* <section className='w-100 bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-8 mx-auto text-dark'>
              <form action="https://getform.io/f/cf8082bf-1bd9-4ddd-bac2-244da2245b68" method="POST" className='row' id='demo-form' e style={rowPadding}>
                <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
                <p className='col-12 col-md-6 mb-4 w-100 text-center'>
                  If you have any questions regarding our premium plans and web themes or other inquiries, please fill out the form below and we will get back to you as soon as possible.
                </p>
                <p className='col-12 col-md-6'>
                    <label htmlFor="name" className='form-label'>Name:</label>
                    <input type="text" id="name" name="name" className='form-control' />
                </p>
                <p className='col-12 col-md-6'>
                    <label htmlFor="email" className='form-label'>Email:</label>
                    <input type="email" id="email" name="email" className='form-control' />
                </p>
                <p className='col-12 col-md-6'>
                    <label htmlFor="tel" className='form-label'>Phone:</label>
                    <input type="tel" id="tel" name="tel" className='form-control' />
                </p>
                <p className='col-12 col-md-6'>
                    <label htmlFor="company-organization" className='form-label'>Company / Organization:</label>
                    <input type="text" id="company-organization" name="company-organization" className='form-control' />
                </p>
                <p>
                    <label htmlFor="message" className='form-label'>Mesage:</label>
                    <textarea id="message" rows="5" className='form-control'></textarea>
                </p>

                <button type="submit" className="btn btn-orange default-padding shadow text-capitalize" role="button" style={{ width: '150px' }}>Send</button>
              </form>
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  )
}
