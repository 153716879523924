import React, { useEffect, useState, useCallback }  from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const rowPadding = {
  padding: "100px 0"
}

function App() {

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState('')
 
  const initialValues = { name: "", email: "", phone: "", company: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [loading, setLoading] = useState(false); 
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {

    // if(e) {
      console.log(e)
      const { name, value } = e.target;
      // console.log(name, value);
      setFormValues({ ...formValues, [name]: value });
      console.log(formValues);
      // return;
    // }
    
    setFormValues(initialValues)
  };

  const recaptchaToken = async () => {
    try {
      const result = await executeRecaptcha('homepage');
      return result;
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    // handleChange();

    // console.log(new handleChange().test)



    // formValues.name = '';
    // setFormValues({...formValues, [name]: ''})

    // handleChange({name: '', value: ''});
    // setFormValues({ ...formValues });
    // console.log(handleChange(initialValues));
  };

  const submitData = token => {
    // call a backend API to verify reCAPTCHA response
    if(token === undefined)
      return;

    // fetch('/submit', {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({
    //     "name": formValues.name,
    //     "email": formValues.email,
    //     "phone": formValues.phone,
    //     "company": formValues.company,
    //     "message": formValues.message,
    //     "token": token
    //   })
    // })
    // .then(res => res.json())
    // .then(res => {
    //   setLoading(false);
    //   setResponse(res);
    //   // console.log({ ...initialValues });
    //   // setFormValues({ ...formValues, initialValues })
    //   console.log(res);
    // });
  }

  useEffect(() => {
    if(Object.keys(formErrors).length === 0) {
      recaptchaToken().then(value => 
        submitData(value)
      );
    }
  }, [formErrors])

  const validate = (values) => {
    const errors = {};
    const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
    const phone_regex = new RegExp(/^[0-9\b]+$/);
    
    if (!values.name) {
      errors.name = "This field is required!";
    }
    if (!values.email) {
      errors.email = "This field is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phone) {
      errors.phone = "This field is required!";
    } else if (!phone_regex.test(values.phone)) {
      errors.phone = "This is not a valid phone number!";
    }
    if (!values.company) {
      errors.company = "This field is required!";
    }
    return errors;
  };
 
  return (
    <>
      <section className='w-100 bg-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-8 mx-auto text-dark'>

                {/* {Object.keys(formErrors).length === 0 && isSubmit ? (
                  <div className="ui message success">Signed in successfully</div>) : (
                  <pre>{JSON.stringify(formValues, undefined, 2)} </pre>
                )} */}

                <form method="POST" className='row' id='demo-form' onSubmit={handleSubmit} style={rowPadding}>
                  <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
                  <p className='col-12 col-md-6 mb-4 w-100 text-center'>
                    If you have any questions regarding our premium plans and web themes or other inquiries, please fill out the form below and we will get back to you as soon as possible.
                  </p>
                  <p className='col-12 col-md-6'>
                      <label htmlFor="name" className='form-label'>Name:</label>
                      <input type="text" id="name" name="name" className='form-control' onChange={handleChange} defaultValue={formValues.name} />
                      <div className='invalid-feedback' style={{display: 'block'}}>{formErrors.name}</div>
                  </p>
                  <p className='col-12 col-md-6'>
                      <label htmlFor="email" className='form-label'>Email:</label>
                      <input type="text" id="email" name="email" className='form-control' onChange={handleChange} defaultValue={formValues.email} />
                      <div className='invalid-feedback' style={{display: 'block'}}>{formErrors.email}</div>
                  </p>
                  <p className='col-12 col-md-6'>
                      <label htmlFor="tel" className='form-label'>Phone:</label>
                      <input type="tel" id="tel" name="phone" className="form-control" onChange={handleChange} defaultValue={formValues.phone} />
                      <div className='invalid-feedback' style={{display: 'block'}}>{formErrors.phone}</div>
                  </p>
                  <p className='col-12 col-md-6'>
                      <label htmlFor="company" className='form-label'>Company / Organization:</label>
                      <input type="text" id="company" name="company" className='form-control' onChange={handleChange} defaultValue={formValues.company} />
                      <div className='invalid-feedback' style={{display: 'block'}}>{formErrors.company}</div>
                  </p>
                  <p>
                      <label htmlFor="message" className='form-label'>Mesage:</label>
                      <textarea id="message" name='message' rows="5" className='form-control' onChange={handleChange} defaultValue={formValues.message}></textarea>
                  </p>

                  {/* <div id="inline-badge"></div> */}

                  <div className='col-12'>
                    <button type="submit" id="submit" className="btn btn-orange default-padding shadow text-capitalize" role="button" style={{ width: '150px' }} disabled={loading}>{loading ? 'Sending...' : 'Send'}</button>
                  </div>
                  
                  {/* {response && <label>Output:<br /><pre>{JSON.stringify(response, undefined, 2)}</pre></label>} */}

                  {response && <div class="alert alert-success alert-dismissible fade show mt-3" role="alert"> {response} <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>}

                </form>
              </div>
            </div>
          </div>
      </section>
    </>    
  );
}
 
export default App;